import React, { useCallback, useRef, useState } from "react";
import { styled } from "goober";
import TextInput from "clutch/src/TextInput/TextInput.jsx";

import testId from "@/util/test-id.mjs";

const Form = styled("form")`
  background: #f08;

  button {
    background: #08f;
    color: #fff;
  }
`;

export default function TestForm() {
  const [value, setValue] = useState<string | undefined>(undefined);
  const [isActive, setIsActive] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const submit = useCallback<React.FormEventHandler>((event) => {
    event.preventDefault();
    setValue(`${nameRef.current?.value}!`);
  }, []);
  const toggle = useCallback(() => {
    setIsActive(true);
  }, []);

  /* eslint-disable i18next/no-literal-string */
  return (
    <>
      <Form onSubmit={submit} {...testId("form")}>
        <input {...testId("input-name")} name="name" ref={nameRef} />
        <button {...testId("form-submit")}>Submit</button>
      </Form>
      <div {...testId("output")}>{value && `Form submitted! ${value}`}</div>
      {!isActive ? (
        <div onClick={toggle}>Toggle</div>
      ) : (
        <TextInput
          defaultValue={value}
          placeholder={"wat"}
          onValueChange={(v) => setValue(v)}
        />
      )}
    </>
  );
  /* eslint-enable i18next/no-literal-string */
}

export function meta() {
  return {
    title: [null, "Test form"],
    description: [null, "Test form"],
  };
}
